<script>
import { ref } from "vue";
import { Thumbs } from "swiper";
import Axios from "@/config/axios";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";

import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination, Navigation]);

export default {
  page: {
    title: "Products Details",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };

    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
    };
  },
  async beforeRouteEnter({ params }) {
    let current_product = {};
    await Axios.get("/admin/products/" + params.id).then(({ data }) => {
      current_product = data.data;
    });

    localStorage.setItem("@CURRENT_PRODUCT", JSON.stringify(current_product));
  },
  beforeMount() {
    this.current_product = JSON.parse(
      localStorage.getItem("@CURRENT_PRODUCT") || "[]"
    );
  },
  data() {
    return {
      current_product: {},
      title: "Products Details",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Products Details",
          active: true,
        },
      ],
      productDetailsWidgets: [
        {
          id: 1,
          icon: "ri-money-dollar-circle-fill",
          label: "Prix",
          field: "sale_price",
        },
        {
          id: 2,
          icon: "ri-file-copy-2-fill",
          label: "No. de commandes",
          field: "orders",
        },
        {
          id: 3,
          icon: "ri-stack-fill",
          label: "Quantité en stock",
          field: "stock",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    Swiper,
    SwiperSlide,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row gx-lg-5">
              <div class="col-xl-4 col-md-8 mx-auto">
                <div
                  v-if="current_product.name"
                  class="product-img-slider sticky-side-div"
                >
                  <swiper
                    :modules="[Thumbs]"
                    class="product-thumbnail-slider p-2 rounded bg-light"
                    :navigation="{
                      nextEl: '.swiper-button-next bg-white shadow',
                      prevEl: '.swiper-button-prev bg-white shadow',
                    }"
                    :thumbs="{
                      swiper: thumbnailSwiper,
                    }"
                  >
                    <swiper-slide
                      v-for="image of current_product.large_pictures"
                      :key="image.id"
                    >
                      <img :src="image.large" class="img-fluid d-block" />
                    </swiper-slide>
                  </swiper>
                  <div class="swiper-button-next"></div>
                  <div class="swiper-button-prev"></div>
                  <!-- end swiper thumbnail slide -->
                  <swiper
                    :modules="[Thumbs]"
                    class="product-nav-slider mt-2"
                    :loop="false"
                    :spaceBetween="10"
                    :slidesPerView="4"
                    :freeMode="true"
                    watch-slides-progress
                    @swiper="setThumbsSwiper"
                  >
                    <swiper-slide
                      v-for="image of current_product.small_pictures"
                      :key="image.id"
                    >
                      <div class="nav-slide-item">
                        <img :src="image.small" alt class="img-fluid d-block" />
                      </div>
                    </swiper-slide>
                  </swiper>
                  <!-- end swiper nav slide -->
                </div>
              </div>
              <!-- end col -->

              <div class="col-xl-8">
                <div class="mt-xl-0 mt-5">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h4>{{ current_product.name }}</h4>
                      <div class="hstack gap-3 flex-wrap">
                        <div class="vr"></div>
                        <div class="text-muted">
                          Publié le :
                          <span class="text-body fw-medium">{{
                            new Date(
                              current_product.created_at
                            ).toLocaleString()
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex-shrink-0">
                      <div>
                        <router-link
                          :to="'/product-update/' + current_product.id"
                          class="btn btn-light"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                        >
                          <i class="ri-pencil-fill align-bottom"></i>
                        </router-link>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-wrap gap-2 align-items-center mt-3">
                    <div class="text-muted fs-16">
                      <span
                        v-for="i in 5"
                        :key="i"
                        class="mdi mdi-star"
                        :class="{
                          'text-warning': i <= current_product.ratings,
                        }"
                      ></span>
                    </div>
                    <div class="text-muted">
                      ( {{ current_product.reviews }} commentaires )
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div
                      class="col-lg-4 col-sm-6"
                      v-for="(item, index) of productDetailsWidgets"
                      :key="index"
                    >
                      <div class="p-2 border border-dashed rounded">
                        <div class="d-flex align-items-center">
                          <div class="avatar-sm me-2">
                            <div
                              class="avatar-title rounded bg-transparent text-success fs-24"
                            >
                              <i :class="`${item.icon}`"></i>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <p class="text-muted mb-1">{{ item.label }} :</p>
                            <h5 class="mb-0">
                              {{ current_product[item.field] || 0 }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end col -->
                  </div>

                  <div class="mt-4 text-muted">
                    <h5 class="fs-14">Description :</h5>
                    <p>
                      {{ current_product.short_description }}
                    </p>
                  </div>

                  <div class="product-content mt-5">
                    <h5 class="fs-14 mb-3">Spécifications sur le produit :</h5>
                    <nav>
                      <ul
                        class="nav nav-tabs nav-tabs-custom nav-success"
                        id="nav-tab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="nav-speci-tab"
                            data-bs-toggle="tab"
                            href="#nav-speci"
                            role="tab"
                            aria-controls="nav-speci"
                            aria-selected="true"
                            >Spécifications</a
                          >
                        </li>
                      </ul>
                    </nav>
                    <div
                      class="tab-content border border-top-0 p-4"
                      id="nav-tabContent"
                    >
                      <div
                        class="tab-pane fade show active"
                        id="nav-speci"
                        role="tabpanel"
                        aria-labelledby="nav-speci-tab"
                      >
                        <div class="table-responsive">
                          <table class="table mb-0">
                            <tbody>
                              <tr
                                v-for="item of current_product.details"
                                :key="item.id"
                              >
                                <th scope="row" style="width: 200px">
                                  {{ item.key }}
                                </th>
                                <td>{{ item.value }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- product-content -->

                  <div class="mt-5">
                    <div>
                      <h5 class="fs-14 mb-3">Note & Commentaires</h5>
                    </div>
                    <div class="row gy-4 gx-0">
                      <div class="col-lg-4">
                        <div>
                          <div class="pb-3">
                            <div class="bg-light px-3 py-2 rounded-2 mb-2">
                              <div class="d-flex align-items-center">
                                <div class="flex-grow-1">
                                  <div class="fs-16 align-middle text-warning">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-half-fill"></i>
                                  </div>
                                </div>
                                <div class="flex-shrink-0">
                                  <h6 class="mb-0">
                                    {{ current_product.ratings }} sur 5
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="text-center">
                              <div class="text-muted">
                                Total
                                <span class="fw-medium">{{
                                  current_product.reviews
                                }}</span>
                                avis
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- end col -->

                      <div class="col-lg-8">
                        <div class="ps-lg-4">
                          <div class="d-flex flex-wrap align-items-start gap-3">
                            <h5 class="fs-14">Commentaires :</h5>
                          </div>

                          <div
                            class="me-lg-n3 pe-lg-4"
                            data-simplebar
                            style="max-height: 225px"
                          >
                            <ul class="list-unstyled mb-0">
                              <li
                                v-for="review of current_product.product_reviews"
                                :key="review.id"
                                class="py-2"
                              >
                                <div class="border border-dashed rounded p-3">
                                  <div class="d-flex align-items-start mb-3">
                                    <div class="hstack gap-3">
                                      <div
                                        class="badge rounded-pill bg-success mb-0"
                                      >
                                        <i class="mdi mdi-star"></i>
                                        {{ review.rating }}
                                      </div>
                                      <div class="vr"></div>
                                      <div class="flex-grow-1">
                                        <p class="text-muted mb-0">
                                          {{ review.message }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="d-flex flex-grow-1 gap-2 mb-3">
                                    <a href="#" class="d-block">
                                      <img
                                        src="@/assets/images/small/img-12.jpg"
                                        alt
                                        class="avatar-sm shadow rounded object-cover"
                                      />
                                    </a>
                                    <a href="#" class="d-block">
                                      <img
                                        src="@/assets/images/small/img-11.jpg"
                                        alt
                                        class="avatar-sm shadow rounded object-cover"
                                      />
                                    </a>
                                    <a href="#" class="d-block">
                                      <img
                                        src="@/assets/images/small/img-10.jpg"
                                        alt
                                        class="avatar-sm shadow rounded object-cover"
                                      />
                                    </a>
                                  </div>

                                  <div class="d-flex align-items-end">
                                    <div class="flex-grow-1">
                                      <h5 class="fs-14 mb-0">
                                        {{ review.name }}
                                      </h5>
                                    </div>

                                    <div class="flex-shrink-0">
                                      <p class="text-muted fs-13 mb-0">
                                        {{
                                          new Date(
                                            review.created_at
                                          ).toLocaleString()
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end Ratings & Reviews -->
                  </div>
                  <!-- end card body -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
